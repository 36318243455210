<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Контактные реквизиты
        <div class="card-header-actions">
          <b-button
            v-if="$store.getters.isAdmin"
            variant="primary"
            size="sm"
            to="/contact-requisites/add"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="`/contact-requisites/id${props.row.id}`"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`customers/id${$store.state.user.ownerId}/contact-requisites/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Удалить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteRequisite(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {contactRequisitesList, contactRequisitesDelete} from '../../services/api';

Vue.use(ServerTable);

export default {
  name: 'MyContactsList',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      loading: false,
      columns: ['id', 'name', 'fullname', 'position', 'actions'],
      data: [],
      options: {
        requestFunction: (data) => {
          this.loading = true;
          return contactRequisitesList(data);
        },
        responseAdapter({data}) {
          console.log(data);
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          status: '',
          id: 'ID',
          name: 'Название',
          fullname: 'ФИО',
          position: 'Должность',
          actions: '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  methods: {
    confirmDeleteRequisite(contactRequisiteId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить контактные реквизиты?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteRequisite(contactRequisiteId);
          }
        });
    },
    async deleteRequisite(contactRequisiteId) {
      this.loading = true;
      const response = await contactRequisitesDelete(contactRequisiteId);
      if (response && response.status === 200) {
        this.showSuccess('Контактные реквизиты удалены');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
  },

};
</script>

<style lang="scss">
</style>
